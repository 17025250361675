function getTermCategoryInfo(category = '') {
  const categoryInfo = {
    category: '',
    title: ''
  }
  if (category === 'service') {
    categoryInfo.category = 'service'
    categoryInfo.title = '서비스 이용약관'
  } else if (category === 'privacy') {
    categoryInfo.category = 'privacy'
    categoryInfo.title = '개인정보처리방침'
  } else if (category === 'event') {
    categoryInfo.category = 'event'
    categoryInfo.title = '이벤트/혜택정보약관'
  } else if (category === 'marketing') {
    categoryInfo.category = 'marketing'
    categoryInfo.title = '헬퍼 광고, 마케팅 약관'
  }
  return categoryInfo
}

export default getTermCategoryInfo
