<template>
  <v-form
    ref="form"
    v-model="valid"
    :lazy-validation="lazy"
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <PageTitleComponent :title="`${pageCategoryTitle} ${mode_text}`" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="item.title"
            label="제목"
            :placeholder="`제목을 입력해 주세요 <예: ${pageCategoryTitle} (2020-11)>`"
            :hint="`제목을 입력해 주세요 <예: ${pageCategoryTitle} (2020-11)>`"
            :rules="[rules.required]"
            clearable
            clear-icon="clear"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="mt-4"
        >
          <p class="subtitle-1">
            활성화 적용여부(적용 시 다른 활성화 된 약관은 비활성됩니다)
          </p>
          <v-switch
            v-model="item.is_activated"
            :label="(item.is_activated) ? '활성화' :'비활성화'"
          />
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col cols="12">
          <p class="subtitle-1">
            내용
          </p>
          <Editor
            v-model="item.content"
            class="mt-2"
          />
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="mt-5"
      >
        <v-col
          cols="auto"
        >
          <v-btn
            v-if="mode==='add'"
            class="mx-4"
            color="primary"
            @click="saveItem"
          >
            <v-icon small>
              add
            </v-icon>
            <span class="pl-2">약관 등록</span>
          </v-btn>
          <v-btn
            v-if="mode==='modify'"
            class="mx-4"
            color="primary"
            @click="updateItem"
          >
            <v-icon small>
              edit
            </v-icon>
            <span class="pl-2">약관 수정</span>
          </v-btn>
          <v-btn
            v-if="mode==='modify'"
            class="mx-4"
            color="error"
            @click="deleteConfirmSheetView = !deleteConfirmSheetView"
          >
            <v-icon small>
              delete
            </v-icon>
            <span class="pl-2">삭제</span>
          </v-btn>
          <v-btn
            color=""
            class="mx-4"
            @click="backToListPage"
          >
            <v-icon small>
              list
            </v-icon>
            <span class="pl-2">목록으로</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-bottom-sheet
      v-model="deleteConfirmSheetView"
      persistent
    >
      <v-sheet
        class="text-center"
        height="250px"
      >
        <div class="py-3">
          정말 삭제하시겠습니까?
          <v-btn
            class="mx-3"
            color="primary"
            @click="deleteItem"
          >
            <span>Y</span>
          </v-btn>
          <v-btn
            class="mx-3"
            color=""
            @click="deleteConfirmSheetView = !deleteConfirmSheetView"
          >
            <span>N</span>
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </v-form>
</template>
<script>
// import Editor from '@/components/editor/MarkdownEditor'
import Editor from '../_elements/editor/MavonEditor'
import PageTitleComponent from '../_elements/title/PageTitle'
import getTermCategoryInfo from './termInfo'
import Api from './api/termsApi'

export default {
  components: {
    Editor,
    PageTitleComponent
  },
  data() {
    return {
      pageCategoryTitle: '',
      termCategory: '',
      valid: false,
      lazy: false,
      deleteConfirmSheetView: false,
      mode: '',
      mode_text: '',
      item: {
        idx: undefined,
        title: '',
        is_activated: false,
        content: ''
      },
      rules: {
        required: (value) => !!value || '꼭 입력해주세요'
      }
    }
  },
  watch: {
    $route(to) {
      this.initPageMode(to)
    }
  },
  created() {
    this.initPageMode(this.$route)
  },
  methods: {
    initPageMode(currentRoute) {
      const categoryInfo = getTermCategoryInfo(this.$route.params.category)
      this.pageCategoryTitle = categoryInfo.title
      this.termCategory = categoryInfo.category

      if (currentRoute.name === 'Service.Terms.AddItem') {
        this.mode = 'add'
        this.mode_text = '등록'
        this.item.content = `# ${this.pageCategoryTitle}\r\n\r\n## 부제목\r\n\r\n내용`
      } else if (currentRoute.name === 'Service.Terms.Item') {
        this.mode = 'modify'
        this.mode_text = '확인, 수정'
        this.item.idx = currentRoute.params.idx
        this.getContent()
      }
    },
    async getContent() {
      if (this.item.idx === undefined) {
        return
      }
      const res = await Api.getItem(this.termCategory, this.item.idx)
      if (res.data.result === true) {
        this.item = res.data.data.item
      }
    },
    async saveItem() {
      if (this.$refs.form.validate() === false) {
        return
      }
      const res = await Api.addItem(this.termCategory, this.item.title, this.item.content, this.item.is_activated)
      if (res.data.result === true) {
        this.$bus.$emit('successMessage', '등록되었습니다')
        this.backToListPage()
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    },
    async updateItem() {
      if (this.$refs.form.validate() === false) {
        return
      }
      const res = await Api.updateItem(
        this.termCategory,
        this.item.idx, this.item.title, this.item.content, this.item.is_activated
      )
      if (res.data.result === true) {
        this.$bus.$emit('successMessage', '수정되었습니다')
        this.backToListPage()
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    },
    async deleteItem() {
      const res = await Api.deleteItem(
        this.termCategory,
        this.item.idx
      )
      if (res.data.result === true) {
        this.$bus.$emit('successMessage', '삭제되었습니다')
        this.backToListPage()
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    },
    backToListPage() {
      const latestHistory = this.$store.getters['App/routerHistoryLatest']
      if (latestHistory.name === 'Service.Terms.List') {
        this.$router.go(-1)
      } else {
        this.$router.push(`/service/terms_of_${this.termCategory}/list?page=1`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table {
  ::v-deep table > thead > tr > th {
    background-color: #EEEEEE;
  }
}
.v-skeleton-loader {
  ::v-deep .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}
</style>
