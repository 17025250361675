import { Api, makeGetUrl } from '@/libraries/api'

function getList(category = '', page = 1, limit = 10, query = '') {
  const url = `/admin/service/terms_of_${category}/list`
  const params = {
    page,
    limit,
    query
  }
  return Api().get(makeGetUrl(url, params))
}

function getItem(category = '', idx) {
  const url = `/admin/service/terms_of_${category}/item/${idx}`
  return Api().get(url)
}

function addItem(category, title, content, isActivated) {
  const url = `/admin/service/terms_of_${category}/item_add`
  const data = {
    title,
    content,
    is_activated: isActivated
  }
  return Api().post(url, data)
}

function updateItem(category, idx, title, content, isActivated) {
  const url = `/admin/service/terms_of_${category}/item/${idx}`
  const data = {
    title,
    content,
    is_activated: isActivated
  }
  return Api().put(url, data)
}

function deleteItem(category, idx) {
  const url = `/admin/service/terms_of_${category}/item/${idx}`
  return Api().delete(url)
}

export default {
  getList,
  getItem,
  addItem,
  updateItem,
  deleteItem
}
